<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Background Tasks</v-card-title>

      <v-data-table
        :headers="headers"
        :items="tasks"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:item.created="{ item }">
          <router-link :to="{name: 'eventmanagerTask', params: { id:event.id, taskId: item.id }}">{{ item.created | localDate('ddd L LTS Z') }}</router-link>
        </template>
      </v-data-table>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue'

export default {
  name: "Task",
  components: {
    Header,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      tasks: [],
      headers: [
        { text: 'Created', value: 'created', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Completed?', value: 'completed', sortable: false },
        { text: 'Has Error?', value: 'has_error', sortable: false },
        { text: 'Progress', value: 'progress', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getEvent(id, taskId) {
      this.tasks = (await eventManagerService.getTasks(id)).data.data;
      console.log('model', this.tasks);
    },

    async getData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Background Tasks`, disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

